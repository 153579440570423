// import { getSessionContext } from "@shopware-pwa/api-client";
import { reloadNuxtApp } from "nuxt/app";

export function useMaintenance(destination: string = '/maintenance/', type: 'not-allowed' | 'maintenance' = 'not-allowed', errorCode: number = 0) {
  const config = useRuntimeConfig();
  const { apiInstance } = useShopwareContext();
  let route = useRoute();
  let errorState = errorCode;

  useIntervalFn(async () => {
    // Ping to store api
    try {
      await apiInstance.invoke.get('/store-api/maintenance');

      errorState = 200;
    } catch (e: any) {
      errorState = e.statusCode;
    }

    changeSite(config.public.MAINTENANCE || errorState == 503);
  }, 100000);

  function changeSite(maintenance: boolean) {
    if (type == 'maintenance') {
      if (!maintenance) {
        reloadNuxtApp({
          path: "/",
          ttl: 1000, // default 10000
        });
      } else if (route.path !== destination) {
        if (maintenance) navigateTo(destination);
      }

    } else {
      if (maintenance) navigateTo(destination);
    }
  }

  changeSite(config.public.MAINTENANCE || errorState == 503);
}